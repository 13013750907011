<template>
  <v-form
    v-show="!isLoadingService"
    ref="ServiceForm"
    @submit.prevent="handleSave"
  >
    <v-container class="form__container pt-6" fluid>
      <v-row>
        <v-col cols="12" class="d-flex align-center">
          <h6 class="text-h6">
            {{ $trans("service_settings") }}
          </h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <plus-field
            v-model="form.category_id"
            :disabled="$loading()"
            :rules="[rules.required]"
            outlined
            small-chips
            deletable-chips
            :label="$trans('category')"
            action="category/fetchWithServices"
            getter="category/categoryWithServices"
            dialog-type="ADD_CATEGORY"
            is-config-dialog
            :no-data-text="$trans('add_categories_with_plus')"
          />
        </v-col>
      </v-row>
      <service-name-and-variants
        :disabled="$loading()"
        :data="servicePriceVariants"
        @change="setServicePriceVariants"
      />
    </v-container>
    <v-divider light class="mt-6 mb-6" />
    <v-container class="form__container" fluid>
      <v-row>
        <v-col>
          <h6 class="text-h6" v-text="$trans('service_location')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("service_location_info") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <location-select-field
            v-model="form.locations"
            :disabled="$loading()"
            chips
            multiple-selection
            deletable-chips
            keep-menu-open-on-select
          />
        </v-col>
      </v-row>
    </v-container>
    <v-divider light class="mt-6 mb-6" />
    <booking-settings
      :disabled="$loading()"
      :current-settings="currentSettings"
      @booking-data="getBookingSettings"
    />
    <v-divider light class="mt-6 mb-6" />
    <employee-panel
      :disabled="$loading()"
      :current-employees="currentEmployees"
      :show-error="showEmployeesError"
      @employees-data="getSelectedEmployees"
    />
    <v-divider light class="mt-6 mb-6" />
    <v-container class="form__container" fluid>
      <v-row>
        <v-col cols="12">
          <h6 class="text-h6" v-text="$trans('service_availability_title')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-card
            min-height="200"
            outlined
            class="pointer fill-height calendesk-card"
            :class="{
              'accent calendesk-card__active': useEmployeesWorkSchedules,
            }"
            @click="useEmployeesWorkSchedules = true"
          >
            <div class="d-flex align-center fill-height">
              <v-avatar class="ma-4" size="50" tile>
                <v-icon v-if="useEmployeesWorkSchedules" color="green">
                  $check
                </v-icon>
                <v-icon v-else color="accent"> $calendar-account</v-icon>
              </v-avatar>
              <div>
                <v-card-title class="break-word-normal">
                  {{ $trans("service_availability_employees_schedule_title") }}
                </v-card-title>
                <v-card-text>
                  {{ $trans("service_availability_employees_info") }}
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            min-height="200"
            outlined
            class="pointer fill-height calendesk-card"
            :class="{
              'accent calendesk-card__active': !useEmployeesWorkSchedules,
            }"
            @click="useEmployeesWorkSchedules = false"
          >
            <div class="d-flex align-center fill-height flex-wrap">
              <div class="d-flex align-center">
                <v-avatar class="ma-4" size="50" tile>
                  <v-icon v-if="!useEmployeesWorkSchedules" color="green">
                    $check
                  </v-icon>
                  <v-icon v-else color="accent"> $calendar</v-icon>
                </v-avatar>
                <div style="width: 100%">
                  <v-card-title class="break-word-normal">
                    {{ $trans("service_availability_service_schedule_title") }}
                  </v-card-title>
                  <v-card-text>
                    {{ $trans("service_availability_service_info") }}
                  </v-card-text>
                </div>
              </div>

              <div
                v-show="!useEmployeesWorkSchedules"
                style="width: 100%"
                class="ma-2"
              >
                <availability-schedule
                  v-model="form.availability_schedule_id"
                  :disabled="$loading() || useEmployeesWorkSchedules"
                  :rules="!useEmployeesWorkSchedules ? [rules.required] : []"
                />
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-divider light class="mt-6 mb-6" />
    <v-container class="form__container" fluid>
      <v-row>
        <v-col cols="12" class="d-flex align-center">
          <h6 class="text-h6">
            {{ $trans("upselling_title") }}
          </h6>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12">
              <calendesk-information-message>
                {{ $trans("upselling_info") }}
              </calendesk-information-message>
            </v-col>
            <v-col>
              <plus-field
                v-model="form.simple_store_products"
                :disabled="$loading()"
                multiple
                chips
                deletable-chips
                outlined
                :label="$trans('simple_store_products')"
                action="simpleStore/fetchAllProducts"
                getter="simpleStore/get"
                dialog-type="ADD_UPDATE_SIMPLE_STORE_PRODUCT"
                :dialog-size="dialogSize.MIDDLE"
                :no-data-text="$trans('add_simple_store_products_with_plus')"
                :dialog-title="$trans('simple_store_product_add_title')"
                dialog-cta-button-action="handleSave"
                dialog-cta-button-icon="$plus-circle"
                :dialog-cta-button-title="$trans('add')"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="form__container" fluid>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="showAdvanceSettings"
            :label="$trans('advance_settings_label')"
            hide-details
          />
        </v-col>
      </v-row>
      <template v-if="showAdvanceSettings">
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="form.description"
              hide-details="auto"
              rows="8"
              outlined
              :disabled="$loading()"
              :label="$trans('service_description')"
              :rules="[rules.maxChars(5000)]"
            />
          </v-col>
          <v-col cols="12" md="6">
            <calendesk-image-select
              v-model="form.default_image"
              avatar
              :select-image-button-text="$trans('select')"
              :remove-image-button-text="$trans('remove')"
              :remove-image-function="removeAvatar"
              @input="setAvatar"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <tip-tap
              v-model="form.upselling_description"
              :placeholder="$trans('upselling_description_title')"
              :label="$trans('upselling_description_title')"
              :subtitle="$trans('upselling_description_subtitle')"
              @input="form.upselling_description = $event"
            />
            <calendesk-information-message
              v-if="
                form.upselling_description &&
                form.upselling_description.length > 5000
              "
              color="red-light"
              additional-class="danger--text my-4"
              icon-color="danger"
              icon="$alert"
            >
              {{ $trans("max_n_characters", { max: 5000 }) }}
            </calendesk-information-message>
          </v-col>
        </v-row>
        <v-row v-if="hasServiceDays" no-gutters>
          <v-col cols="12">
            <v-checkbox
              v-model="showServiceDays"
              :label="$trans('service_days_title')"
              hide-details
            />
          </v-col>
        </v-row>
        <template v-if="showServiceDays">
          <v-row>
            <v-col cols="12">
              <calendesk-information-message>
                {{ $trans("service_days_description") }}
              </calendesk-information-message>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="10" xl="6">
              <service-availability-picker v-model="form.days" />
            </v-col>
          </v-row>
        </template>
        <v-row no-gutters>
          <v-col cols="12">
            <v-checkbox
              v-model="showResources"
              :label="$trans('resources_info')"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row v-if="showResources">
          <v-col cols="12">
            <calendesk-information-message>
              {{ $trans("resource_info") }}<br />
              {{ $trans("resource_info_2") }}
            </calendesk-information-message>
          </v-col>
          <v-col>
            <plus-field
              v-model="form.resources"
              :disabled="$loading()"
              multiple
              chips
              deletable-chips
              outlined
              :label="$trans('resources')"
              action="resource/fetchAll"
              getter="resource/get"
              dialog-type="ADD_OR_UPDATE_RESOURCE"
              :no-data-text="$trans('add_resources_with_plus')"
              is-config-dialog
              :get-item-text="getResourceItemText"
            />
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="form.status"
            :disabled="$loading()"
            false-value="inactive"
            true-value="active"
            :label="$trans('service_active')"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-divider light />
    <v-container class="form__container" fluid>
      <v-row>
        <v-col class="text--secondary">
          {{ $trans("fields_required") }}
        </v-col>
      </v-row>
      <v-row align="center" justify="start">
        <v-col cols="12">
          <v-btn
            :disabled="$loading()"
            :loading="$loading()"
            color="primary"
            type="submit"
          >
            <span v-text="selectedService ? $trans('save') : $trans('add')" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import {
  email,
  maxChars,
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import ServiceAvailabilityPicker from "@/components/CSchedule/ServiceAvailabilityPicker.vue";
import AvailabilitySchedule from "@/components/AvailabilitySchedule.vue";
import {
  employeePermissions,
  hasPermission,
  pushEvent,
} from "@/lib/calendesk-js-library/tools/helpers";
import TipTap from "@/lib/calendesk-js-library/components/TipTap.vue";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import LocationSelectField from "@/components/Forms/LocationSelectField.vue";

export default {
  name: "ServiceFullForm",
  components: {
    LocationSelectField,
    TipTap,
    AvailabilitySchedule,
    ServiceAvailabilityPicker,
    CalendeskInformationMessage,
    EmployeePanel: () => import("./ServiceEmployeePanel.vue"),
    CalendeskImageSelect: () =>
      import("@/lib/calendesk-js-library/components/CalendeskImageSelect.vue"),
    BookingSettings: () => import("./ServiceBookingSettings.vue"),
    PlusField: () => import("@/components/Forms/PlusField.vue"),
    ServiceNameAndVariants: () => import("./ServiceNameAndVariants.vue"),
  },
  mixins: [sharedActions],
  data() {
    return {
      isLoadingService: false,
      showServiceDays: false,
      hasServiceDays: false,
      showResources: false,
      showAdvanceSettings: false,
      useEmployeesWorkSchedules: true,
      invalidForm: false,
      avatar: null,
      isServiceActive: true,
      currentService: null,
      durationTime: null,
      showEmployeesError: false,
      rules: {
        required,
        email,
        minChars,
        maxChars,
      },
      variantsToSend: null,
      employeesToSend: null,
      servicePriceVariants: null,
      currentEmployees: [],
      form: {
        id: null,
        category_id: null,
        name: null,
        duration: null,
        price: null,
        description: null,
        resources: [],
        simple_store_products: [],
        upselling_description: null,
        employees: [],
        days: [],
        wants_invoice: false,
        tax: null,
        allow_online_payment: true,
        only_subscriptions: false,
        awaiting_booking_payment_time: 60,
        payment_required: true,
        locations: null,
        booking_time_before: 1440,
        cancel_time_before: 1440,
        change_time_before: 1440,
        max_booking_time: 525600,
        break_time_before: 0,
        break_time_after: 0,
        slots_every: 15,
        max_people: 1,
        status: "active",
        types: [],
        default_image_id: null,
        availability_schedule_id: null,
      },
    };
  },
  computed: {
    dialogSize() {
      return dialogSize;
    },
    ...mapGetters({
      selectedService: "service/getSelectedService",
      categoryId: "category/getCategoryId",
      resourceId: "resource/getResourceId",
      getRefreshSimpleStoreProductListStatus:
        "simpleStore/getRefreshSimpleStoreProductListStatus",
      simpleProductLastAddedProduct: "simpleStore/getLastAddedProduct",
    }),
    currentSettings() {
      if (this.selectedService) {
        return {
          allow_online_payment: this.form.allow_online_payment,
          only_subscriptions: this.form.only_subscriptions,
          payment_required: this.form.payment_required,
          booking_time_before: this.form.booking_time_before,
          cancel_time_before: this.form.cancel_time_before,
          change_time_before: this.form.change_time_before,
          max_booking_time: this.form.max_booking_time,
          max_user_bookings: this.form.max_user_bookings,
          break_time_before: this.form.break_time_before,
          break_time_after: this.form.break_time_after,
          slots_every: this.form.slots_every,
          max_people: this.form.max_people,
          wants_invoice: this.form.wants_invoice,
          tax: this.form.tax,
          awaiting_booking_payment_time:
            this.form.awaiting_booking_payment_time,
        };
      }
      return null;
    },
  },
  watch: {
    getRefreshSimpleStoreProductListStatus() {
      this.fetchAllSimpleStoreProducts();
    },
    selectedService(val) {
      if (val !== null) {
        this.fillForm();
      }
    },
    categoryId(val) {
      this.form.category_id = val;
    },
    resourceId(val) {
      if (typeof val === "object") {
        this.form.resources = [val.id, ...this.form.resources];
      } else {
        this.form.resources = [val, ...this.form.resources];
      }
    },
    simpleProductLastAddedProduct(val) {
      if (val) {
        this.form.simple_store_products = [
          val.id,
          ...this.form.simple_store_products,
        ];
      }
    },
  },
  beforeDestroy() {
    this.setSelectedService(null);
    this.setCategoryId(null);
  },
  created() {
    this.setSelectedService(null);
    document.documentElement.scrollTop = 0;
    this.fetchServiceIfRequested();
    if (this.categoryId) {
      this.form.category_id = this.categoryId;
    }
  },
  methods: {
    ...mapActions({
      getService: "service/get",
      createService: "service/add",
      updateService: "service/update",
      createType: "service/addServiceType",
      removeType: "service/deleteServiceType",
      updateType: "service/updateServiceType",
      updateEmployee: "employee/update",
      fetchAllSimpleStoreProducts: "simpleStore/fetchAllProducts",
      refreshResources: "resource/refreshResources",
      refreshLocations: "location/refreshLocations",
      fetchAllEmployees: "employee/fetchAll",
    }),
    ...mapMutations({
      setSelectedService: "service/SET_SELECTED_SERVICE",
      setCategoryId: "category/SET_CATEGORY_ID",
    }),
    setServicePriceVariants(data) {
      this.form.name = data.service.name;
      this.form.duration = data.service.duration;
      this.form.price = data.service.price;
      this.variantsToSend = data.types;
    },
    removeAvatar() {
      this.form.default_image = null;
      this.form.default_image_id = null;
    },
    setAvatar(image) {
      this.form.default_image_id = image ? image.id : null;
    },
    getBookingSettings(val) {
      this.form.allow_online_payment = val.allow_online_payment;
      this.form.only_subscriptions = val.only_subscriptions;
      this.form.payment_required = val.payment_required;
      this.form.booking_time_before = val.booking_time_before;
      this.form.max_booking_time = val.max_booking_time;
      this.form.max_user_bookings = parseInt(val.max_user_bookings);
      this.form.cancel_time_before = val.cancel_time_before;
      this.form.change_time_before = val.change_time_before;
      this.form.break_time_before = val.break_time_before;
      this.form.break_time_after = val.break_time_after;
      this.form.max_people = parseInt(val.max_people);
      this.form.wants_invoice = val.wants_invoice;
      this.form.tax = val.tax;
      this.form.slots_every = val.slots_every;
      this.form.awaiting_booking_payment_time =
        val.awaiting_booking_payment_time;
    },
    getSelectedEmployees(val) {
      this.employeesToSend = val;

      if (this.employeesToSend && this.employeesToSend.length > 0) {
        this.showEmployeesError = false;
      }
    },
    async processVariants() {
      const variantsToAdd = this.variantsToSend.filter((x) => x.isNew);
      let variantsToUpdate = this.variantsToSend.filter(
        (x) => !x.isNew && !x.toRemove,
      );
      let variantsToDelete = this.variantsToSend.filter((x) => x.toRemove);

      if (variantsToDelete.length) {
        variantsToDelete = variantsToDelete.map((x) => ({
          serviceId: this.selectedService.id,
          serviceTypeId: x.id,
        }));
      }

      if (variantsToUpdate.length) {
        variantsToUpdate = variantsToUpdate.map((x) => ({
          serviceId: this.selectedService.id,
          serviceTypeId: x.id,
          price: x.price ? Math.round(x.price * 100) : 0,
          duration: x.duration,
          name: x.name,
        }));
      }

      if (variantsToAdd.length) {
        variantsToAdd.map((x) => {
          x.price = x.price ? Math.round(x.price * 100) : 0;
        });
      }

      const promisesDelete = [];
      const promisesUpdate = [];

      for (let i = 0; i < variantsToAdd.length; i++) {
        await this.createType(variantsToAdd[i]).catch((err) => {
          console.log(err);
        });
      }

      // Run in sequence, Otherwise the order will not match.
      for (let i = 0; i < variantsToDelete.length; i++) {
        promisesDelete.push(this.removeType(variantsToDelete[i]));
      }

      promisesDelete.length &&
        (await Promise.all(promisesDelete).catch((err) => {
          console.log(err);
        }));

      for (let i = 0; i < variantsToUpdate.length; i++) {
        promisesUpdate.push(this.updateType(variantsToUpdate[i]));
      }

      promisesUpdate.length &&
        (await Promise.all(promisesUpdate).catch((err) => {
          console.log(err);
        }));
    },
    processObjectBeforeAdd() {
      const toSend = JSON.parse(JSON.stringify(this.form));
      toSend.price = toSend.price ? Math.round(toSend.price * 100) : 0;
      if (this.employeesToSend) {
        toSend.employees = this.employeesToSend;
      } else {
        delete toSend.employees;
      }

      if (this.variantsToSend && this.variantsToSend.length > 0) {
        toSend.types = this.variantsToSend.map((variant) => ({
          name: variant.name,
          price: variant.price ? Math.round(variant.price * 100) : 0,
          duration: variant.duration,
        }));
      } else {
        toSend.types = null;
      }

      if (toSend.locations && toSend.locations.length > 0) {
        toSend.locations = toSend.locations
          .filter((location) => location.id && location.type)
          .map((location) => {
            const parsedLocation = {};
            parsedLocation.location_id = location.id;
            parsedLocation.type = location.type;

            return parsedLocation;
          });
      }

      if (
        this.showResources &&
        this.form.resources &&
        this.form.resources.length > 0
      ) {
        toSend.resources = this.form.resources.map((resource) => {
          if (typeof resource === "object") {
            return resource.id;
          }

          return resource;
        });
      }

      toSend.simple_store_products = this.form.simple_store_products.map(
        (simpleStoreProduct) => {
          if (typeof simpleStoreProduct === "object") {
            return simpleStoreProduct.id;
          }

          return simpleStoreProduct;
        },
      );

      if (!this.showServiceDays) {
        toSend.days = [];
      }

      if (this.useEmployeesWorkSchedules) {
        toSend.availability_schedule_id = null;
      }

      return toSend;
    },
    async add() {
      const toSend = this.processObjectBeforeAdd();
      try {
        pushEvent("createService");
        await this.createService(toSend);
        this.refreshResources();
        this.refreshLocations();
        this.$router.push({ name: "offer", params: { tab: "services" } });
      } catch (err) {
        errorNotification("service_creation_failed", err);
      } finally {
        this.$loading(false);
      }
    },
    processObjectBeforeUpdate() {
      const toSend = JSON.parse(JSON.stringify(this.form));
      toSend.price = toSend.price ? Math.round(toSend.price * 100) : 0;
      delete toSend.category;
      delete toSend.default_booking_status;
      delete toSend.default_image;
      delete toSend.images;
      delete toSend.order_position;
      if (this.employeesToSend) {
        toSend.employees = this.employeesToSend;
      } else {
        delete toSend.employees;
      }

      if (toSend.locations && toSend.locations.length > 0) {
        toSend.locations = toSend.locations
          .filter((location) => location.id && location.type)
          .map((location) => {
            const parsedLocation = {};
            parsedLocation.location_id = location.id;
            parsedLocation.type = location.type;

            return parsedLocation;
          });
      }

      if (!this.showResources) {
        toSend.resources = [];
      }

      toSend.simple_store_products = this.form.simple_store_products.map(
        (simpleStoreProduct) => {
          if (typeof simpleStoreProduct === "object") {
            return simpleStoreProduct.id;
          }

          return simpleStoreProduct;
        },
      );

      if (!this.showServiceDays) {
        toSend.days = [];
      }

      if (this.useEmployeesWorkSchedules) {
        toSend.availability_schedule_id = null;
      }

      return toSend;
    },
    async update() {
      pushEvent("updateService");

      const toSend = this.processObjectBeforeUpdate();
      try {
        this.variantsToSend && (await this.processVariants());
        await this.updateService(toSend);
        this.refreshResources();
        this.refreshLocations();
        this.$router.push({ name: "offer", params: { tab: "services" } });
      } catch (err) {
        errorNotification("service_update_failed", err);
      } finally {
        this.$loading(false);
      }
    },
    checkValidation() {
      const form = this.$refs.ServiceForm.validate();
      this.showEmployeesError =
        !this.employeesToSend || this.employeesToSend.length < 1;
      this.invalidForm =
        !form ||
        this.showEmployeesError ||
        (this.form.upselling_description &&
          this.form.upselling_description.length > 5000);
    },
    checkServiceVariants() {
      if (this.variantsToSend) {
        const variants = this.variantsToSend.filter((x) => !x.toRemove);
        if (variants && variants.length) {
          const arr = JSON.parse(JSON.stringify(variants));
          arr.sort((a, b) => a.price - b.price);
          this.form.price = arr[0].price;
          this.form.duration = arr[0].duration;
        }
      }
    },
    async handleSave() {
      this.checkServiceVariants();
      this.checkValidation();
      if (!this.invalidForm) {
        this.$loading(true);
        this.form.id ? await this.update() : await this.add();
        await this.clearCache("event-list");

        if (hasPermission(employeePermissions.SHOW_EMPLOYEES)) {
          await this.fetchAllEmployees();
        }
      } else {
        errorNotification("form_is_invalid", {}, false);
        this.$loading(false);
      }
    },
    async fetchServiceIfRequested() {
      const id = this.$route.params.id || this.$route.params.duplicateId;
      if (id) {
        try {
          this.isLoadingService = true;
          this.$loading(true);
          await this.getService(id);
        } catch (err) {
          this.$router.push({ name: "offer", params: { tab: "services" } });
          errorNotification("fetch_data_failed", err, false);
        } finally {
          this.isLoadingService = false;
          this.$loading(false);
        }
      }
    },
    fillForm() {
      if (this.selectedService) {
        const service = JSON.parse(JSON.stringify(this.selectedService));

        this.showServiceDays = service.days && service.days.length > 0;
        this.hasServiceDays = this.showServiceDays;
        this.showResources = service.resources && service.resources.length > 0;

        service.resources = service.resources.map((x) => x.id);
        service.simple_store_products = service.simple_store_products.map(
          (x) => x.id,
        );
        service.price = service.price ? service.price / 100 : 0;
        this.form = { ...service };
        this.form.types.map((_) => {
          _.price = _.price ? _.price / 100 : 0;
        });
        this.form.only_subscriptions = service.only_subscriptions ? 1 : 0;
        this.form.allow_online_payment = service.allow_online_payment ? 1 : 0;
        this.form.payment_required =
          service.allow_online_payment && service.payment_required ? 1 : 0;
        this.form.wants_invoice =
          service.allow_online_payment && service.wants_invoice ? 1 : 0;
        this.useEmployeesWorkSchedules = !this.form.availability_schedule_id;

        if (
          this.showServiceDays ||
          this.showResources ||
          this.form.description ||
          this.form.default_image ||
          this.form.upselling_description
        ) {
          this.showAdvanceSettings = true;
        }

        this.currentEmployees = this.$helpers.cloneObject(
          this.selectedService.employees,
        );

        // The LocationSelectField needs to handle location ids,
        // Service returns as default the id of relation ServiceLocation
        this.form.locations = this.form.locations
          .filter((location) => location.location_id && location.type)
          .map((location) => {
            const parsedLocation = {};
            parsedLocation.id = location.location_id;
            parsedLocation.type = location.type;
            parsedLocation.data = location.data;
            parsedLocation.name = location.name;

            return parsedLocation;
          });

        if (this.$route.params.duplicateId) {
          this.form.types.map((_) => {
            _.id = "temp_" + Math.floor(Math.random() * 10000000);
            _.isNew = true;
          });

          this.variantsToSend = this.$helpers.cloneObject(this.form.types);

          if (
            this.form.default_image &&
            this.form.default_image.user_image_id
          ) {
            this.form.default_image.id = null;
            this.form.default_image_id = this.form.default_image.user_image_id;
          } else {
            this.removeAvatar();
          }

          this.form.id = null;
        }

        this.servicePriceVariants = {
          service: {
            name: this.form.name,
            duration: this.form.duration,
            price: this.form.price,
            id: this.form.id,
          },
          types: this.form.types,
        };
      }
    },
    getResourceItemText(resource) {
      return `(${this.$trans("id")}: ${resource.id}) ${
        resource.name
      } - ${this.$trans("quantity_pcs").toLowerCase()}: ${resource.quantity}`;
    },
  },
};
</script>
